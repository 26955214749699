import React, { useEffect } from "react"
import { AlertInfo } from "../../../../../../component/atoms/AlertInfo/index.jsx"
import { ICON_CONSTANS } from "../../../../../../constants/ImageConstants.js"
import { Button, Card } from "react-bootstrap"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import { RowText } from "../../../../../../component/index.jsx"
import {
  checkValue,
  toHectare,
  toMeter,
  toThousandConvert
} from "../../../../../helpers/TextHelper.js"
import BootstrapTable from "react-bootstrap-table-next"
import { timeStampToDate } from "../../../../../helpers/DateHelper.js"
import { useTranslation } from "react-i18next"
import { ImageWithModal } from "../../../../../../component/atoms/ImageWithModal/index.jsx"
import { useDispatch, useSelector } from "react-redux"
import { getListSeedClass } from "../../../../../../redux/actions/SubmissionAction.jsx"
import { DATE_TYPE_ENUM } from "../../../../../../constants/StringConstant.js"
export const Review = ({ formik, dataInit, activeStep, setActiveStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { General } = useSelector((state) => state)

  useEffect(() => {
    dispatch(getListSeedClass())
  }, [])

  const columnsArea = [
    {
      dataField: "no",
      text: "No",
      headerClasses: "bg-gray"
    },
    {
      dataField: "area_code",
      text: "Nama Lahan",
      headerClasses: "bg-gray"
    },
    {
      dataField: "farmer_name",
      text: "Nama Petani",
      headerClasses: "bg-gray"
    },
    {
      dataField: "land_area",
      text: "Luas Lahan",
      headerClasses: "bg-gray"
    }
  ]

  const dataArea = dataInit.areas.map((item, index) => {
    return {
      no: index + 1,
      area_code: checkValue(item?.area_code),
      farmer_name: checkValue(item?.farmer_name),
      land_area: (
        <div>
          {toMeter(item?.land_area_meter)}&nbsp;(
          {toHectare(item?.land_area_ha)})
        </div>
      )
    }
  })

  const sectionInfoBlockArea = (
    <Card className={"mb-6"}>
      <Card.Body>
        <h3 className={"mb-6 font-weight-bolder"}>INFORMASI BLOK LAHAN</h3>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText label={"Lokasi Lahan"} value={checkValue(dataInit.areas[0]?.location)} />
          <RowText label={"Jumlah Lahan"} value={checkValue(dataInit?.total_area) + " Lahan"} />
          <RowText label={"Mitra"} value={checkValue(dataInit?.mitra_name)} />
          <RowText
            label={"Luas Blok"}
            value={
              <div>
                {toMeter(dataInit?.total_land_area_meter)} (
                {toThousandConvert(dataInit?.total_land_area_ha)} Ha)
              </div>
            }
          />
        </RowModule>

        <h4 className={"mb-6"}>DAFTAR LAHAN</h4>
        <div className={"border rounded table-small-padding"}>
          <BootstrapTable
            condensed
            bordered={false}
            columns={columnsArea}
            keyField={"no"}
            data={dataArea}
          />
        </div>
      </Card.Body>
    </Card>
  )

  const sectionDataPlant = (
    <Card className={"mb-6"}>
      <Card.Body>
        <h3 className={"mb-6 font-weight-bolder"}>DATA TANAMAN</h3>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText label={"Jenis Tanaman"} value={checkValue(dataInit?.plant_type_name)} />
          <RowText
            label={"Kelas Benih"}
            value={dataInit?.seed_class.map((item) => {
              if (item.id === formik.values.seed_class) {
                return item.name
              }
            })}
          />
          <RowText
            label={"Varietas"}
            value={dataInit?.variety_list.map((item) => {
              if (item.variety_id === formik.values.variety_id) {
                return item.variety_name
              }
            })}
          />
        </RowModule>
      </Card.Body>
    </Card>
  )

  const sectionPlantDate = (
    <Card className={"mb-6"}>
      <Card.Body>
        <h3 className={"mb-6 font-weight-bolder"}>DATA TANGGAL TANAM</h3>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText
            label={"Tanggal Tanam Jantan"}
            value={timeStampToDate(
              checkValue(formik.values.male_planting_date),
              DATE_TYPE_ENUM.ISO_DATE_ID
            )}
          />
          <RowText
            label={"Tanggal Tanam Betina"}
            value={timeStampToDate(
              checkValue(formik.values.female_planting_date),
              DATE_TYPE_ENUM.ISO_DATE_ID
            )}
          />
        </RowModule>
      </Card.Body>
    </Card>
  )

  const sectionDataLastPlant = (
    <Card className={"mb-6"}>
      <Card.Body>
        <h3 className={"mb-6 font-weight-bolder"}>DATA TANAMAN SEBELUMNYA</h3>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText
            label={t("label.land_history")}
            value={checkValue(dataInit?.last_planting_data?.land_history)}
          />
          <RowText
            label={t("label.harvest_date")}
            value={checkValue(
              timeStampToDate(
                dataInit?.last_planting_data?.harvest_date,
                DATE_TYPE_ENUM.ISO_DATE_ID
              )
            )}
          />
          <RowText
            label={t("label.former_bero")}
            value={checkValue(dataInit?.last_planting_data?.bero)}
          />
          <RowText
            label={t("label.disertifikasi")}
            value={dataInit?.last_planting_data?.certificate ? "Ya" : "Tidak"}
          />
          <RowText
            label={t("label.plant_type")}
            value={checkValue(dataInit?.last_planting_data?.plant_type)}
          />
          <RowText
            label={t("label.seed_class")}
            value={checkValue(dataInit?.last_planting_data?.seed_class)}
          />
          <RowText
            label={t("label.varietas")}
            value={checkValue(dataInit?.last_planting_data?.variety_name)}
          />
          <RowText
            label={t("label.field_inspection")}
            value={checkValue(dataInit?.last_planting_data?.field_check)}
          />
        </RowModule>
      </Card.Body>
    </Card>
  )

  const sectionSeedFrom = (
    <Card className={"mb-6"}>
      <Card.Body>
        <h3 className={"mb-6 font-weight-bolder"}>DATA ASAL BENIH</h3>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText
            label={"Asal Sumber Benih"}
            value={checkValue(
              dataInit?.variety_list?.find((item) => item.variety_id === formik.values.variety_id)
                ?.variety_source
            )}
          />
          <RowText
            label={"Jumlah Total Benih"}
            value={
              checkValue(
                parseInt(formik.values.female_seed_total) + parseInt(formik.values.male_seed_total)
              ) + " Kg"
            }
          />
          <RowText
            label={"Asal Kelas Benih"}
            value={dataInit?.seed_class.map((item) => {
              if (item.id === formik.values.source_seed_class) {
                return item.name
              }
            })}
          />
        </RowModule>

        <Card className={"mb-6 bg-secondary"}>
          <Card.Body>
            <h4 className={"mb-6"}>Benih Betina</h4>
            <RowModule customColumnCss={"col-md-6 mb-2"}>
              <RowText
                label={"Nama Induk"}
                value={checkValue(
                  dataInit?.variety_list?.find(
                    (item) => item.variety_id === formik.values.variety_id
                  )?.female_base_name
                )}
              />
              <RowText label={"Nomor Lot"} value={checkValue(formik.values.female_lot_number)} />
              <RowText
                label={"Jumlah Benih"}
                value={checkValue(formik.values.female_seed_total) + " Kg"}
              />
              <RowText
                label={"Nomor Seri Label"}
                value={checkValue(formik.values.female_seri_label)}
              />
            </RowModule>
            <div className={"text-dark-50 txt-12 mb-2"}>Foto Label</div>
            <RowModule customColumnCss={"col-md-1 mb-2"}>
              {formik.values?.female_label_images?.map((item, index) => {
                return (
                  <ImageWithModal
                    value={item}
                    key={index}
                    className={""}
                    titleModal={"Foto Label"}
                    imageType={"rectangle"}
                  />
                )
              })}
            </RowModule>
          </Card.Body>
        </Card>
        <Card className={"mb-6 bg-secondary"}>
          <Card.Body>
            <h4 className={"mb-6"}>Benih Jantan</h4>
            <RowModule customColumnCss={"col-md-6 mb-2"}>
              <RowText
                label={"Nama Induk"}
                value={checkValue(
                  dataInit?.variety_list?.find(
                    (item) => item.variety_id === formik.values.variety_id
                  )?.male_base_name
                )}
              />
              <RowText label={"Nomor Lot"} value={checkValue(formik.values.male_lot_number)} />
              <RowText
                label={"Jumlah Benih"}
                value={checkValue(formik.values.male_seed_total) + " Kg"}
              />
              <RowText
                label={"Nomor Seri Label"}
                value={checkValue(formik.values.male_seri_label)}
              />
            </RowModule>
            <div className={"text-dark-50 txt-12 mb-2"}>Foto Label</div>
            <RowModule customColumnCss={"col-md-1 mb-2"}>
              {formik.values?.male_label_images?.map((item, index) => {
                return (
                  <ImageWithModal
                    value={item}
                    key={index}
                    className={""}
                    titleModal={"Foto Label"}
                    imageType={"rectangle"}
                  />
                )
              })}
            </RowModule>
          </Card.Body>
        </Card>
      </Card.Body>
    </Card>
  )

  const sectionImageLocation = (
    <Card>
      <Card.Body>
        <h3 className={"mb-6 font-weight-bolder"}>FOTO LOKASI</h3>
        <RowModule customColumnCss={"col-md-3"}>
          <ImageWithModal
            value={formik.values.location_image}
            className={""}
            titleModal={"Foto Lokasi"}
            imageType={"auto"}
          />
        </RowModule>
      </Card.Body>
    </Card>
  )
  return (
    <div className="d-flex justify-content-center w-full py-6">
      <div className={"w-80vw"}>
        <AlertInfo
          className={"mb-4"}
          variant={"warning"}
          icon={ICON_CONSTANS.IC_WARNING}
          label={"Pastikan anda melakukan pengecekan ulang sebelum mengirim."}
        />
        {sectionInfoBlockArea}
        {sectionDataPlant}
        {sectionPlantDate}
        {sectionDataLastPlant}
        {sectionSeedFrom}
        {sectionImageLocation}
        <div className={"d-flex justify-content-end align-items-center mt-8"}>
          <Button
            variant={"secondary"}
            onClick={() => setActiveStep(activeStep - 1)}
            className={"d-flex align-items-center color-primary mr-8"}>
            Kembali
          </Button>
          <Button
            disabled={General.loadingGlobal}
            variant={"primary"}
            onClick={() => formik.handleSubmit()}
            className={"d-flex align-items-center color-white"}>
            Ajukan Sertifikasi & Blok Lahan
          </Button>
        </div>
      </div>
    </div>
  )
}
