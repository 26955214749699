import React, { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { CardBody } from "../../../../../_metronic/_partials/controls"
import { useSubheader } from "../../../../../_metronic/layout"
import { FileList, RowText } from "../../../../../component"
import { ActivityDetailHeader } from "../../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader"
import { ACTIVITY_ICONS } from "../../../../../constants/ImageConstants"
import { RoutesConstants } from "../../../../../constants/RoutesConstants"
import { DATE_TYPE_ENUM } from "../../../../../constants/StringConstant"
import { getDetailLandClearing } from "../../../../../redux/actions/AreaAction"
import { timeStampToDate } from "../../../../helpers/DateHelper"
import {
  checkValue,
  convertM2ToHectareParseFloat,
  fileTypeSplit
} from "../../../../helpers/TextHelper"

export function LandClearingDetail({
  match: {
    params: { status, id, activityId }
  }
}) {
  const { Area } = useSelector((state) => state)
  const { t } = useTranslation()
  const [data, setData] = useState()
  const subHeader = useSubheader()
  const dispatch = useDispatch()

  useEffect(() => {
    if (activityId) {
      dispatch(getDetailLandClearing(activityId))
    }
  }, [activityId])

  useEffect(() => {
    if (Area?.data) {
      setData(Area?.data)
    }
  }, [Area.data])

  useEffect(() => {
    let newStatus
    if (status === "active") {
      newStatus = "teregistrasi"
    }
    subHeader.setButton(null)
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
        title: "Lahan"
      },
      {
        pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
        title: newStatus
      },
      {
        pathname: "/lahan/list/" + status + "/" + id,
        title: data?.area_information?.area_name
      },
      {
        title: t("label.clearing")
      }
    ])
  }, [data])

  function headerData() {
    return (
      <ActivityDetailHeader
        title={t("label.land_clearing_activity")}
        icon={ACTIVITY_ICONS.IC_LAND_CLEARING}
      />
    )
  }
  return (
    <div style={{ display: "grid", gap: 16 }}>
      {headerData()}
      <Card>
        <CardBody>
          <h4 className={"mb-6 text-uppercase"}>{t("label.area_information")}</h4>
          <div className={"row mb-2"}>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.area_name")}
                value={checkValue(data?.area_information?.area_name)}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.area_location")}
                value={checkValue(data?.area_information?.full_address)}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.block_name")}
                value={checkValue(data?.area_information?.block_code)}
              />
            </div>

            <div className={"col-md-6"}>
              <RowText
                label={t("label.manual_area_size")}
                value={
                  data?.area_information?.area_land_manual
                    ? `${convertM2ToHectareParseFloat(
                        data?.area_information?.area_land_manual,
                        4
                      )} Ha`
                    : "-"
                }
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.certification_number")}
                value={checkValue(data?.area_information?.certification_number)}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.polygon_area_size")}
                value={
                  data?.area_information?.area_land_polygon
                    ? `${convertM2ToHectareParseFloat(
                        data?.area_information?.area_land_polygon,
                        4
                      )} Ha`
                    : "-"
                }
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.farmer")}
                value={`${checkValue(data?.area_information?.farmer_name)} (${checkValue(
                  data?.area_information?.farmer_code
                )})`}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.plant_type")}
                value={checkValue(data?.area_information?.plant_type_name)}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.mitra")}
                value={`${checkValue(data?.area_information?.mitra_name)} (${checkValue(
                  data?.area_information?.mitra_code
                )})`}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.plant_varieties")}
                value={checkValue(data?.area_information?.variety_name)}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h4 className={"mb-6 text-uppercase"}>{t("label.land_clearing_data")}</h4>
          <div className={"row mb-2"}>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.created_by")}
                value={checkValue(data?.land_clearing?.created_by)}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.age_at_activity")}
                value={checkValue(data?.land_clearing?.hst) + " HST"}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.created_date")}
                value={
                  data?.land_clearing?.created_date
                    ? timeStampToDate(
                        data?.land_clearing?.created_date,
                        DATE_TYPE_ENUM.ISO_DATETIME_STRAP
                      )
                    : "-"
                }
              />
            </div>

            <div className={"col-md-6"}>
              <RowText
                label={t("label.land_area_worked")}
                value={
                  data?.land_clearing?.worked_area
                    ? `${convertM2ToHectareParseFloat(data?.land_clearing?.worked_area)} Ha`
                    : "-"
                }
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.activity_date")}
                value={
                  data?.land_clearing?.activity_date
                    ? timeStampToDate(
                        data?.land_clearing?.activity_date,
                        DATE_TYPE_ENUM.ISO_DATE_STRAP
                      )
                    : "-"
                }
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={t("label.land_clearing_reasong")}
                value={checkValue(data?.land_clearing?.reason_string)}
              />
            </div>
          </div>
          <h4 className={"mb-6 "}>{t("event_report")}</h4>
          <div>
            {data && (
              <FileList
                view={checkValue(data?.land_clearing?.document_url)}
                className={"mb-5 col-md-6"}
                name={checkValue(data?.land_clearing?.document_name)}
                size={checkValue(data?.land_clearing?.document_size)}
                type={fileTypeSplit(checkValue(data?.land_clearing?.document_name))}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
