import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { sortCaret } from "../../../../../../_metronic/_helpers/index.js"
import { Card, CardBody, CardHeader } from "../../../../../../_metronic/_partials/controls/index.js"
import { DetailSvgIcon } from "../../../../../../component/atoms/Icons/SVG/index.jsx"
import TableView, { formatterRowComponent } from "../../../../../../component/atoms/Tabel/index.jsx"
import { TableSkeleton } from "../../../../../../component/index.jsx"
import { timeStampToDate } from "../../../../../helpers/DateHelper.js"

export const AreaList = ({ data, general, changePage, title, onClickDetail }) => {
  const uiType = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.activity_name ?? "-"}</p>
      </>
    )
  }
  const uiHST = (e) => {
    return (
      <>
        <p className="text-dark mb-1 font-size-lg">{e?.hst ?? "-"}</p>
      </>
    )
  }
  const uiActivityDate = (e) => {
    return (
      <>
        <p className="text-dark mb-1 font-size-lg">
          {e?.activity_date ? timeStampToDate(e?.activity_date, "dd-MM-yyyy") : "-"}
        </p>
      </>
    )
  }

  const uiCreatedDate = (e) => {
    return (
      <>
        <p className="text-dark mb-1 font-size-lg">
          {e?.submitted_date ? timeStampToDate(e?.submitted_date, "dd-MM-yyyy") : "-"}
        </p>
        <p className="text-dark mb-1 font-size-lg">
          {e?.submitted_date ? timeStampToDate(e?.submitted_date, "HH:mm:ss") : "-"}
        </p>
      </>
    )
  }
  const uiSubmit = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.submitted_by ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">{e?.submitted_phone ?? "-"}</span>
      </>
    )
  }
  const uiAction = (item) => {
    return (
      <>
        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
          <a
            onClick={() => {
              onClickDetail(item.id, item?.activity_type_enum?.toLowerCase())
            }}
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DetailSvgIcon></DetailSvgIcon>
          </a>
        </OverlayTrigger>
      </>
    )
  }

  const columns = [
    {
      dataField: "activity_name",
      text: "Jenis Aktivitas",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiType
      }
    },
    {
      dataField: "activityDate",
      text: "Tanggal Aktivitas",
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiActivityDate
      }
    },
    {
      dataField: "hst",
      text: "HST",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiHST
      }
    },
    {
      dataField: "submitted_by",
      text: "Diajukan Oleh",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiSubmit
      }
    },
    {
      dataField: "createdDate",
      text: "Tanggal Submit",
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCreatedDate
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  return (
    <>
      {general.loading ? (
        <TableSkeleton column={6} row={10} />
      ) : (
        <Card className="card-shadowless">
          {title && <CardHeader title={title} />}

          <CardBody>
            <TableView
              callbackAfterPageChange={changePage}
              columnProperties={columns}
              dataTable={data?.pageData}
              currentPage={data?.page?.currentPage}
              currentElement={data?.page?.currentElement}
              totalCount={data?.page?.totalCount}
              loadingGetData={general?.loading}
              ignoreSinglePage
              rowClasses={() => {
                return ""
              }}
              rowEvents={{
                onClick: () => {}
              }}
            />
          </CardBody>
        </Card>
      )}
    </>
  )
}
