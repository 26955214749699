import React, { useEffect } from "react"
import { Card, Col } from "react-bootstrap"
import { CardBody, CardHeader } from "../../../../../../_metronic/_partials/controls"
import { useTranslation } from "react-i18next"
import { UploadComponent } from "../../../../../../component"
import { UploadIcon } from "../../../../../../component/atoms/Icons/SVG/upload"
import { VARIABLE_CONSTANT } from "../../../../../../constants/VariableConstant"
import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import { resetUrlResponse, uploadAction } from "../../../../../../redux/actions/MasterDataAction"
import { UPLOAD } from "../../../../../../constants/InitTypeConstants"

const DocumentUpload = ({ fileAttachments, setFileAttachments }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { MasterData, General } = useSelector((state) => state)
  const { setFieldValue } = useFormikContext()

  const onChangeFileUpload = (e) => {
    setFileAttachments([e])
    setFieldValue("document_name", e.name)
    setFieldValue("document_size", e.size)
    dispatch(uploadAction({ data: e, type: UPLOAD.BUSINESS_DOCUMENT }))
  }

  const onRemove = () => {
    setFileAttachments([])

    setFieldValue("document_url", undefined)
    setFieldValue("document_name", undefined)
    setFieldValue("document_size", undefined)

    return
  }

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (!General.loading) {
        if (MasterData.uploadType === UPLOAD.BUSINESS_DOCUMENT) {
          setFieldValue("document_url", MasterData.urlResponseUpload)
        }
      }

      setTimeout(() => {
        dispatch(resetUrlResponse())
      }, 200)
    }
  }, [MasterData.urlResponseUpload, General.loading, MasterData.uploadType])

  return (
    <Card className="mb-4">
      <CardHeader>
        <h5 className="font-size-h5" style={{ color: "#353535" }}>
          {t("event_report")}
        </h5>
      </CardHeader>
      <CardBody>
        <Col>
          <UploadComponent
            label={"Dokumen Berita Acara"}
            required={true}
            name="document_name"
            onDeleteFileList={onRemove}
            fileList={fileAttachments}
            typeUpload={"pdf"}
            editable={true}
            onChange={onChangeFileUpload}
            placeholder={<UploadIcon />}
            maxSize={VARIABLE_CONSTANT.MAX_FILE_UPLOAD_PDF}
            maxSizeText={VARIABLE_CONSTANT.MAX_SIZE_PDF}
            isLoading={General.loading}
          />
        </Col>
      </CardBody>
    </Card>
  )
}

export default DocumentUpload
