import React, { Fragment, useEffect, useRef, useState } from "react"
import { PopupQuestion } from "../../../../../../component/atoms/Popup/index.jsx"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../../../helpers/StringCollection.js"
import { useTranslation } from "react-i18next"
import { EmptyCard, Search, SelectInput } from "../../../../../../component/index.jsx"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import { useDispatch, useSelector } from "react-redux"
import {
  clearListAreaCertification,
  clearListAreaMapCertification,
  getListAreaCertification,
  getListAreaMapCertification,
  getMitraFilterCertification,
  getSubDistrictByMitraFilterCertification,
  getVarietyFilterCertification
} from "../../../../../../redux/actions/SubmissionAction.jsx"
import { getUrlParsingSubmission } from "../../../../../../config/EndpointCollection.js"
import {
  initDate,
  SelectDateRange
} from "../../../../../../component/atoms/SelectDateRange/index.jsx"
import { dateSelect, timeStampToDate } from "../../../../../helpers/DateHelper.js"
import { format } from "date-fns"
import { Checkbox, CircularProgress, FormControlLabel } from "@material-ui/core"
import { CardAreaSertification } from "../../../../../../component/atoms/CardAreaSertification/index.jsx"
import {
  ICON_CONSTANS,
  ILLUSTRATION_ASSETS_V2
} from "../../../../../../constants/ImageConstants.js"
import { CardAreaSertificaionSkeleton } from "../../../../../../component/atoms/CardAreaSertification/skeleton.jsx"
import { SUBMISSION } from "../../../../../../constants/InitTypeConstants.js"
import { GoogleMap, Marker, Polygon, useLoadScript } from "@react-google-maps/api"
import { coordinateCenterPolygon, GoogleMapKey } from "../../../../../helpers/MapHelper.js"
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers/index.js"
import { FloatingSelectArea } from "../../../../../../component/atoms/FloatingSelectArea/index.jsx"
import { convertDigits, toHectare, toMeter } from "../../../../../helpers/TextHelper.js"
import { toast } from "react-toastify"
import SVG from "react-inlinesvg"
import { DATE_TYPE_ENUM } from "../../../../../../constants/StringConstant.js"

export const SelectArea = ({ selectedArea, setSelectedArea, activeStep, setActiveStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { Submission, General } = useSelector((state) => state)

  const [showFilter, setShowFilter] = useState(true)
  const [mitraList, setMitraList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [varietasList, setVarietasList] = useState([])
  const [openDate, setOpenDate] = useState(false)
  const [selectedDate, setSelectedDate] = useState(false)
  const [selectedAll, setSelectedAll] = useState(false)
  const [listArea, setListArea] = useState([])

  // map
  const mapRef = useRef(null)
  const [center, setCenter] = useState({})
  const [listAreaMap, setListAreaMap] = useState([])
  const [expand, setExpand] = useState(false)
  const [zoom, setZoom] = useState(14)
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GoogleMapKey()
  })

  const formik = useFormik({
    initialValues: {
      keyword: "",
      mitra_id: "",
      sub_district_id: "",
      variety_id: "",
      planting_date: initDate
    },
    validationSchema: yup.object({
      keyword: yup.string(),
      mitra_id: yup
        .string()
        .nullable()
        .required(getStringErrorMassage("Mitra", FORM_NEED.harusDiIsi)),
      sub_district_id: yup
        .string()
        .nullable()
        .required(getStringErrorMassage("Lokasi", FORM_NEED.harusDiIsi)),
      variety_id: yup
        .string()
        .nullable()
        .required(getStringErrorMassage("Varietas Tanaman", FORM_NEED.harusDiIsi)),
      planting_date: yup
        .array()
        .required(getStringErrorMassage("Rencana Tanam Betina", FORM_NEED.harusDiIsi))
    }),
    onSubmit: (values) => {
      const param = getUrlParsingSubmission({
        mitra_id: values.mitra_id,
        sub_district_id: values.sub_district_id,
        variety_id: values.variety_id,
        planting_date_start: format(
          values.planting_date[0].startDate,
          DATE_TYPE_ENUM.ISO_DATE_EURO
        ),
        planting_date_end: format(values.planting_date[0].endDate, DATE_TYPE_ENUM.ISO_DATE_EURO)
      })
      dispatch(getListAreaCertification(param))
      dispatch(getListAreaMapCertification(param))
    }
  })

  useEffect(() => {
    dispatch(getMitraFilterCertification())
    dispatch(getVarietyFilterCertification())

    return () => {
      dispatch(clearListAreaCertification())
      dispatch(clearListAreaMapCertification())
    }
  }, [])

  useEffect(() => {
    if (formik.values.keyword) {
      const data = Submission.listArea
      const result = [...data].filter((e) =>
        e.area_code.toUpperCase().includes(formik.values.keyword.toUpperCase())
      )
      setListArea(result)
    } else {
      setListArea(Submission.listArea)
    }
  }, [Submission.listArea, formik.values.keyword])

  useEffect(() => {
    if (formik.values.keyword) {
      const data = Submission.listAreaMap
      const result = [...data].filter((e) =>
        e.area_code.toUpperCase().includes(formik.values.keyword.toUpperCase())
      )
      setListAreaMap(result)
    } else {
      setListAreaMap(Submission.listAreaMap)
    }
  }, [Submission.listAreaMap, formik.values.keyword])

  useEffect(() => {
    if (formik.values.mitra_id) {
      const param = getUrlParsingSubmission({
        mitra_id: formik.values.mitra_id
      })
      dispatch(getSubDistrictByMitraFilterCertification(param))
    }
  }, [formik.values.mitra_id])

  useEffect(() => {
    if (Submission.filterMitra.length) {
      setMitraList(
        Submission.filterMitra.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
    if (Submission.filterSubDistrict.length) {
      setLocationList(
        Submission.filterSubDistrict.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
    if (Submission.filterVarietas.length) {
      setVarietasList(
        Submission.filterVarietas.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
  }, [Submission])

  const onMapLoad = (map) => {
    navigator?.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
      const pos = { lat, lng }
      setCenter(pos)
    })
    mapRef.current = map
  }

  useEffect(() => {
    let allArea = []
    listAreaMap.map((a) => {
      allArea.push(a.centroid)
    })
    setCenter(coordinateCenterPolygon(allArea))
    setZoom(17)
  }, [listAreaMap])

  useEffect(() => {
    formik.setFieldValue("sub_district_id", "")
  }, [formik.values.mitra_id])

  const poligonStyle = (active) => {
    return {
      fillColor: active ? "#6CC049" : "#FFFFFF",
      fillOpacity: 0.7,
      strokeColor: "#353535",
      strokeOpacity: 1,
      strokeWeight: 2,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1
    }
  }

  const bodyFilter = (
    <FormikProvider value={formik}>
      <RowModule>
        <SelectInput
          name="mitra_id"
          required
          onChange={(value) => formik.setFieldValue("mitra_id", value)}
          editable={true}
          label={"Mitra"}
          placeholder="Pilih Mitra"
          value={formik.values.mitra_id}
          options={mitraList}
        />
      </RowModule>

      <RowModule>
        <SelectInput
          name="sub_district_id"
          required
          onChange={(value) => formik.setFieldValue("sub_district_id", value)}
          editable={formik.values.mitra_id ? true : false}
          label={"Lokasi"}
          placeholder="Pilih Lokasi"
          value={formik.values.sub_district_id}
          options={locationList}
        />
      </RowModule>

      <RowModule>
        <SelectInput
          name="variety_id"
          required
          onChange={(value) => formik.setFieldValue("variety_id", value)}
          editable={true}
          label={"Varietas Tanaman"}
          placeholder="Pilih Varietas Tanaman"
          value={formik.values.variety_id}
          options={varietasList}
        />
      </RowModule>

      <SelectDateRange
        label={t("label.planting_date")}
        required={true}
        openDate={openDate}
        setOpenDate={setOpenDate}
        placeholder={"Pilih Tanggal Tanam"}
        value={formik.values.planting_date}
        setValue={(e) => formik.setFieldValue("planting_date", e)}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        maxRange={5}
      />
    </FormikProvider>
  )

  const filterValueDisplay = [
    {
      label: t("label.mitra"),
      value: mitraList.map((item) => {
        if (item.value === formik.values.mitra_id) {
          return item.label
        }
      })
    },
    {
      label: t("label.area_location"),
      value: locationList.map((item) => {
        if (item.value === formik.values.sub_district_id) {
          return item.label
        }
      })
    },
    {
      label: t("label.varietas"),
      value: varietasList.map((item) => {
        if (item.value === formik.values.variety_id) {
          return item.label
        }
      })
    },
    {
      label: t("label.planting_date"),
      value:
        dateSelect(format(formik.values.planting_date[0].startDate, DATE_TYPE_ENUM.ISO_DATE_EURO)) +
        " s/d " +
        dateSelect(format(formik.values.planting_date[0].endDate, DATE_TYPE_ENUM.ISO_DATE_EURO))
    }
  ]

  const filterSection = (
    <div
      className={
        "d-flex justify-content-between align-items-center py-3 px-8 bg-white border-1 border-bottom border-bottom-light-dark"
      }>
      <div className={"filter-container"}>
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <Search
            name={"keyword"}
            placeholder={"Cari nama lahan"}
            initialValues={{ keyword: "" }}
            onSubmitForm={(value) => {
              formik.setFieldValue("keyword", value.keyword)
              formik.handleSubmit()
            }}
            trigerReset={() => {
              formik.setFieldValue("keyword", "")
              formik.handleSubmit()
            }}
          />
        </div>
      </div>

      <div className={"d-flex justify-content-around align-items-center flex-1"}>
        {filterValueDisplay.map((item, index) => {
          return (
            <div key={index} className={"border-1 border-left pl-4"}>
              <div className={"color-gray"}>{item.label}</div>
              <div>{item.value}</div>
            </div>
          )
        })}
      </div>
      <div
        className={"color-gray cursor-pointer font-weight-bold underline font-size-lg"}
        onClick={() => setShowFilter(true)}>
        Ubah Filter
      </div>
    </div>
  )

  const listAreaSection = (
    <div className={`overflow-hidden overflow-auto ${expand ? "w-0 d-none" : "w-50"}`}>
      <div className={"px-6 py-4 d-flex justify-content-between align-items-start"}>
        <div>
          Hasil Pencarian : <span className={"font-weight-bold"}>{listArea.length} Lahan</span>
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox color={"primary"} checked={selectedAll} onChange={() => selectAll()} />
            }
            label="Pilih semua lahan"
          />
        </div>
      </div>
      {General.loadingComponent && General.actionInitType === SUBMISSION.GET_LIST_AREA ? (
        <div className={"px-6 py-4 row overflow-auto min-h-submission max-h-submission"}>
          {Array.from({ length: 10 }).map((_, index) => {
            return (
              <div className={"col-6"} key={index}>
                <CardAreaSertificaionSkeleton />
              </div>
            )
          })}
        </div>
      ) : listArea.length ? (
        <div className={"px-6 py-4 row overflow-auto max-h-submission"}>
          {listArea.map((item, index) => {
            return (
              <div className={"col-6"} key={index}>
                <CardAreaSertification
                  className={
                    selectedArea.includes(item.area_id)
                      ? "bg-primary-o-10 border border-1 border-primary"
                      : ""
                  }
                  area_id={item.area_id}
                  area_code={item.area_code}
                  land_area_ha={item.land_area_ha}
                  land_area_meter={item.land_area_meter}
                  female_planting_date={timeStampToDate(
                    item.planting_date,
                    DATE_TYPE_ENUM.ISO_DATE_STRAP
                  )}
                  mitra_name={item.mitra_name}
                  variety_name={item.variety_name}
                  sub_district_name={item.sub_district_name}
                  handleSelect={(e) => handleSelectedArea(e)}
                  labelSelect={selectedArea.includes(item.area_id) ? "Batal" : "Pilih"}
                  labelColor={selectedArea.includes(item.area_id) ? "inherit" : "secondary"}
                />
              </div>
            )
          })}
        </div>
      ) : (
        <div
          className={
            "d-flex justify-content-center align-items-center w-full h-full min-h-submission bg-white"
          }>
          <EmptyCard
            image={ILLUSTRATION_ASSETS_V2.IL_EMPTY_DESKTOP}
            body={t("messages.response.searching_not_found")}
            subtitle={t("messages.response.empty_search_subtitle")}
          />
        </div>
      )}
    </div>
  )

  const mapAreaSection = (
    <div className={`h-full position-relative ${expand ? "w-100" : "w-50"}`}>
      {General.loadingComponent && General.actionInitType === SUBMISSION.GET_LIST_AREA_MAP && (
        <div className={"loading-map"}>
          <CircularProgress className="splash-screen-spinner" size={20} />
          <div className={"ml-2"}>Loading...</div>
        </div>
      )}
      <div className={"expand-map cursor-pointer"} onClick={() => setExpand(!expand)}>
        <img
          src={ICON_CONSTANS.IC_ARROW_EXPAND}
          alt={""}
          className={expand ? "fa-rotate-180" : ""}
        />
      </div>
      <Fragment>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "calc(100vh - 122px)"
          }}
          zoom={zoom}
          center={center}
          onLoad={(map) => onMapLoad(map)}
          mapTypeId={"satellite"}>
          {listAreaMap.map((item, index) => {
            return (
              <div key={index}>
                <Marker
                  position={item.centroid}
                  label={{
                    text: item.area_code,
                    className: "label-map"
                  }}
                  icon={toAbsoluteUrl(ICON_CONSTANS.IC_MARKER_TRANSPARENT)}
                />
                <Polygon
                  key={index}
                  paths={item.coordinates}
                  options={poligonStyle(selectedArea.includes(item.area_id) ? true : false)}
                />
              </div>
            )
          })}
        </GoogleMap>
      </Fragment>
    </div>
  )

  function handleSelectedArea(id) {
    const selected = selectedArea
    const index = selected.indexOf(id)
    if (index !== -1) {
      selected.splice(index, 1)
      setSelectedArea([...selected])
    } else {
      setSelectedArea([...selected, id])
    }
  }

  useEffect(() => {
    if (selectedArea.length > 0) {
      if (selectedArea.length === listArea.length) {
        setSelectedAll(true)
      } else {
        setSelectedAll(false)
      }
    }
  }, [selectedArea])

  function selectAll() {
    if (!selectedAll) {
      const areaSelect = []
      listArea.map((item) => {
        areaSelect.push(item.area_id)
      })
      setSelectedArea(areaSelect)
      setSelectedAll(true)
    } else {
      setSelectedArea([])
      setSelectedAll(false)
    }
  }

  function checkTotalAreaMeter() {
    let total = 0
    listArea.map((item) => {
      if (selectedArea.includes(item.area_id)) {
        total = Number(total) + Number(item.land_area_meter)
      }
    })
    return total
  }

  function checkAreaHa() {
    let total = 0
    listArea.map((item) => {
      if (selectedArea.includes(item.area_id)) {
        total = Number(total) + Number(item.land_area_ha)
      }
    })
    return convertDigits(total, 4)
  }

  const totalLandArea = (
    <div className={"d-flex align-items-center"}>
      ({toMeter(checkTotalAreaMeter())} / {toHectare(checkAreaHa())})
    </div>
  )

  return (
    <>
      {selectedArea.length > 0 && (
        <FloatingSelectArea
          totalArea={selectedArea.length}
          totalLandArea={totalLandArea}
          buttonlabel={"Isi Formulir"}
          buttonColor={"primary"}
          withIcon={true}
          handleClick={() => {
            if (checkAreaHa() > 10) {
              toast(t("label.canot_more_than"), {
                position: "top-center",
                autoClose: 5000,
                style: {
                  background: "#353535"
                },
                icon: <SVG src={ICON_CONSTANS.IC_BULLET_CLOSE} />,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark"
              })
            } else {
              setActiveStep(activeStep + 1)
            }
          }}
        />
      )}
      <PopupQuestion
        onlyOk={true}
        disable={
          formik.values.mitra_id &&
          formik.values.sub_district_id &&
          formik.values.variety_id &&
          formik.values.planting_date &&
          selectedDate
            ? false
            : true
        }
        textOk={t("button.apply")}
        title={t("Filter")}
        onOk={() => {
          setSelectedAll(false)
          setSelectedArea([])
          formik.handleSubmit()
          setShowFilter(false)
        }}
        show={showFilter}
        bodyCustom={bodyFilter}
        onCloseButton={() => {
          if (
            formik.values.mitra_id &&
            formik.values.sub_district_id &&
            formik.values.variety_id &&
            formik.values.planting_date &&
            selectedDate
          ) {
            setShowFilter(false)
          }
        }}
        closeButton={true}
        size={"lg"}
      />
      {filterSection}
      <div className={"d-flex"}>
        {listAreaSection}
        {isLoaded && mapAreaSection}
      </div>
    </>
  )
}
