import { useHtmlClassService, useSubheader } from "../../../../../_metronic/layout/index.js"
import { useLocation, useParams } from "react-router"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useUrlParam } from "../../../../hooks/useUrlParams.jsx"
import { useDispatch, useSelector } from "react-redux"
import { initialFilterTable } from "../../../../../component/atoms/Tabel/index.jsx"
import { getUrlParsingSubmission } from "../../../../../config/EndpointCollection.js"
import {
  getCertificationList,
  getMitraFilterCertification,
  getSubDistrictByMitraFilterCertification,
  getVarietyFilterCertification,
  getSummaryDataCertification,
  clearDEtailDownloadSubmission
} from "../../../../../redux/actions/SubmissionAction.jsx"
import { Button, Card, Col, Row } from "react-bootstrap"
import { CardHeader } from "@material-ui/core"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { FilterCard, SelectInput, TabsWithIcon } from "../../../../../component/index.jsx"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { DATE_TYPE_ENUM, ROUTING_STATUS } from "../../../../../constants/StringConstant.js"
import { CertificationBlockPending } from "./tab/CertificationBlockPending.jsx"
import AddIcon from "@material-ui/icons/Add"
import { CertificationBlock } from "./tab/CertificationBlock.jsx"
import { DateRange } from "react-date-range"
import { CalenderSvgIcon } from "../../../../../component/atoms/Icons/SVG/calender.jsx"
import { dateSelect } from "../../../../helpers/DateHelper.js"
import { format } from "date-fns"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"

export const ListCertificationBlock = ({ history }) => {
  const subHeader = useSubheader()
  const { tab } = useParams()
  const formRef = useRef()
  const { t } = useTranslation()
  const uiService = useHtmlClassService()

  const location = useLocation()
  const urlParams = useUrlParam()
  const isReadOnly = false
  const dispatch = useDispatch()
  const { Submission } = useSelector((state) => state)
  const privileges = loadUserLogin().privilege

  const [listDataSummary, setListDataSummary] = useState([])
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [isActiveFilter, setIsActiveFilter] = useState(false)
  const [isActiveFilterEmptyState, setIsActiveFilterEmptyState] = useState(false)
  const [tabActive, setTabActive] = useState(checkTabActive(tab))
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const [dataListCertification, setDataListCertification] = useState(null)
  const [mitraFilterList, setMitraFilterList] = useState([])
  const [mitraFilterValue, setMitraFilterValue] = useState("")
  const [varietyFilterList, setVarietyFilterList] = useState([])
  const [varietyFilterValue, setVarietyFilterValue] = useState("")
  const [locationFilterList, setLocationFilterList] = useState([])
  const [locationFilterValue, setLocationFilterValue] = useState("")
  const [btnFilterDisable, setBtnFilterDisable] = useState(false)
  const [openDate, setOpenDate] = useState(false)
  const [dateRange, setDateRange] = useState("")
  const [state, setState] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection"
    }
  ])

  const [initialValue, setInitialValue] = useState({
    ...initialFilterTable,
    mitra_id: "",
    sub_district_id: "",
    variety_id: "",
    planting_date_start: "",
    planting_date_end: ""
  })
  useEffect(() => {
    dispatch(clearDEtailDownloadSubmission())
    dispatch(getMitraFilterCertification())
    dispatch(getVarietyFilterCertification())
    dispatch(getSubDistrictByMitraFilterCertification(""))
    uiService.setExitFullPage()
  }, [])

  useEffect(() => {
    if (mitraFilterValue) {
      const param = getUrlParsingSubmission({
        mitra_id: mitraFilterValue
      })
      dispatch(getSubDistrictByMitraFilterCertification(param))
    }
  }, [mitraFilterValue])

  useEffect(() => {
    if (isActiveFilter) {
      setIsActiveFilterEmptyState(true)
    } else if (isActiveSearch) {
      setIsActiveFilterEmptyState(true)
    } else {
      setIsActiveFilterEmptyState(false)
    }
  }, [isActiveSearch, isActiveFilter, location])

  useEffect(() => {
    subHeader.setTitle(t(`label.submission_certification_block`))
    if (privileges.indexOf(PRIVILEGE_ENUM.CERTIFICATION_CREATE_SUBMISSION) > -1) {
      subHeader.setButton(
        <Button
          onClick={() => {
            history.push(RoutesConstants.SUBMISSION.CREATE_SUBMISSION())
          }}>
          <Row>
            <Col md={"auto"}>
              <AddIcon />
            </Col>
            <Col md={"auto"}>{t("label.create_certification")}</Col>
          </Row>
        </Button>
      )
    }
    dispatch(getSummaryDataCertification())
    if (!urlParams) dispatch(getCertificationList(checkStatusByActiveTab(tabActive), ""))
  }, [])

  useEffect(() => {
    setDataListCertification(Submission)
  }, [Submission?.pageData])

  useEffect(() => {
    if (
      formRef.current.values.mitra_id ||
      formRef.current.values.sub_district_id ||
      formRef.current.values.variety_id ||
      dateRange
    )
      setBtnFilterDisable(false)
    else setBtnFilterDisable(true)
  }, [formRef.current, mitraFilterValue, locationFilterValue, varietyFilterValue, dateRange])

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location?.search])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  useEffect(() => {
    const data = Submission?.summaryData
    const dataList = [
      { label: "label.pending", value: data.pending ?? 0 },
      { label: "label.submitted", value: data.submitted ?? 0 },
      { label: "label.received", value: data.approved ?? 0 },
      { label: "label.rejected", value: data.rejected ?? 0 }
    ]
    setListDataSummary(dataList)
  }, [Submission])

  useEffect(() => {
    setTabActive(checkTabActive(tab))
  }, [tab])

  useEffect(() => {
    checkQueryParamOnPageLoad()
  }, [queryStringObj])

  useEffect(() => {
    if (Submission.filterMitra.length) {
      setMitraFilterList(
        Submission.filterMitra.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
    if (Submission.filterSubDistrict.length) {
      setLocationFilterList(
        Submission.filterSubDistrict.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
    if (Submission.filterVarietas.length) {
      setVarietyFilterList(
        Submission.filterVarietas.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
  }, [Submission])

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingSubmission({
          ...initialValue,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          keyword: searchValue
        })
      } else {
        urlParam = getUrlParsingSubmission({
          ...initialValue,
          ...param,
          keyword: searchValue
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, searchValue, initialValue]
  )

  function onSubmitFilter(e) {
    const valueArray = 0
    const { values, submited } = e
    if (submited) {
      setIsActiveFilter(true)
      setIsShowFilter(false)
      const paramData = {
        ...initialFilterTable,
        keyword: searchValue,
        mitra_id: values.mitra_id,
        sub_district_id: values.sub_district_id,
        variety_id: values.variety_id,

        planting_date_start: state[valueArray].startDate
          ? format(state[valueArray].startDate, DATE_TYPE_ENUM.ISO_DATE_EURO)
          : "",
        planting_date_end: state[valueArray].endDate
          ? format(state[valueArray].endDate, DATE_TYPE_ENUM.ISO_DATE_EURO)
          : ""
      }
      if (paramData) {
        setInitialValue(paramData)
        const urlParam = getUrlParsingSubmission(paramData)
        history.push({ search: urlParam })
      }
    }
  }

  function onSearch(values) {
    setSearchValue(values?.keyword)
    setIsActiveSearch(true)
    let param = {
      ...initialValue,
      keyword: values?.keyword
    }
    const urlParam = getUrlParsingSubmission(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  function handleOpenCloseFilter(value) {
    setIsShowFilter(value)
  }

  function handleResetSearch() {
    setSearchValue("")
    setIsActiveSearch(false)
    const param = {
      ...initialValue,
      keyword: ""
    }
    const urlParam = getUrlParsingSubmission(param)

    history.push({ search: urlParam })
  }

  function fetchData(param) {
    dispatch(getCertificationList(checkStatusByActiveTab(tabActive), param))
  }

  function checkQueryParamOnPageLoad() {
    if (queryStringObj?.keyword) {
      setSearchValue(queryStringObj?.keyword)
      setIsActiveSearch(true)
    }
    if (
      queryStringObj?.variety_id ||
      queryStringObj?.mitra_id ||
      queryStringObj?.sub_district_id ||
      queryStringObj?.planting_date_start ||
      queryStringObj?.planting_date_end
    ) {
      const data = {
        ...initialFilterTable,
        keyword: queryStringObj?.keyword,
        variety_id: queryStringObj?.variety_id,
        mitra_id: queryStringObj?.mitra_id,
        sub_district_id: queryStringObj?.sub_district_id,
        planting_date_start: queryStringObj?.planting_date_start,
        planting_date_end: queryStringObj?.planting_date_end
      }
      if (data) {
        setIsActiveFilter(true)
        setInitialValue(data)
        setMitraFilterValue(queryStringObj?.mitra_id)
        setVarietyFilterValue(queryStringObj?.variety_id)
        setLocationFilterValue(queryStringObj?.sub_district_id)
        if (data?.planting_date_end && data.planting_date_start) {
          let selectedDate =
            dateSelect(format(new Date(data.planting_date_start), DATE_TYPE_ENUM.ISO_DATE_EURO)) +
            " s/d " +
            dateSelect(format(new Date(data.planting_date_end), DATE_TYPE_ENUM.ISO_DATE_EURO))
          setDateRange(selectedDate)
          setState([
            {
              startDate: new Date(data?.planting_date_start),
              endDate: new Date(data?.planting_date_end),
              key: "selection"
            }
          ])
        } else {
          setDateRange("")
        }
      }
    }
  }

  function summaryCard() {
    return (
      <div className={"d-print-none mb-10"}>
        <Card>
          <div className={"d-flex p-10 align-items-center justify-content-between"}>
            {listDataSummary.map((item, i) => (
              <div key={i}>
                <div className={"text-black-50 mb-2"}>{t(item.label)}</div>
                <h1>{item.value}</h1>
              </div>
            ))}
          </div>
        </Card>
      </div>
    )
  }

  function submitLog() {
    const valueArray = 0
    let selectedDate =
      dateSelect(format(state[valueArray].startDate, DATE_TYPE_ENUM.ISO_DATE_EURO)) +
      " s/d " +
      dateSelect(format(state[valueArray].endDate, DATE_TYPE_ENUM.ISO_DATE_EURO))
    setDateRange(selectedDate)
    setOpenDate(false)
  }

  function resetDate() {
    setOpenDate(false)
    setDateRange("")
    setState([
      {
        startDate: "",
        endDate: "",
        key: "selection"
      }
    ])
  }

  function dateRangePicker() {
    return (
      <div style={{ transform: `translateY(23px)` }}>
        <div className={"date-picker-custom "} style={{ fontSize: 11 }}>
          <DateRange
            className={"font-10"}
            onChange={(item) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="horizontal"
            preventSnapRefocus={true}
            calendarFocus="backwards"
          />
          <div className={"action-wrapper d-flex justify-content-end py-4 px-4 border-top"}>
            <button onClick={resetDate} type={"button"} className={"btn btn-outline-primary mx-4"}>
              {t("label.reset_date")}
            </button>
            <button onClick={submitLog} type={"button"} className={"btn btn-primary mx-4"}>
              {t("label.apply")}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function selectDate() {
    return (
      <>
        <label></label>
        <div className={"d-flex justify-content-end"}>
          <div
            onClick={() => setOpenDate(!openDate)}
            className={
              "select-date d-flex justify-content-between align-items-center cursor-pointer"
            }>
            <label className={"mb-0 txt-12 cursor-pointer"}>
              {dateRange ? dateRange : "Pilih Range Tanggal"}
            </label>
            <CalenderSvgIcon />
          </div>
        </div>
      </>
    )
  }

  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Mitra" className="px-0 font-card-header" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="mitra_id"
              label={t("label.mitra_name")}
              editable={!isReadOnly}
              placeholder="Pilih Salah Satu"
              options={mitraFilterList}
              withoutFeedback={true}
              isClearable={true}
              value={mitraFilterValue}
              onChange={(e) => setMitraFilterValue(e)}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Kelurahan" className="px-0 font-card-header" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="sub_district_id"
              label={t("label.sub_district")}
              editable={!isReadOnly}
              placeholder="Pilih Salah Satu"
              options={locationFilterList}
              withoutFeedback={true}
              isClearable={true}
              value={locationFilterValue}
              onChange={(e) => setLocationFilterValue(e)}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Varietas" className="px-0 font-card-header" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="variety_id"
              label={t("label.variety_name")}
              editable={!isReadOnly}
              placeholder="Pilih Salah Satu"
              options={varietyFilterList}
              withoutFeedback={true}
              isClearable={true}
              value={varietyFilterValue}
              onChange={(e) => setVarietyFilterValue(e)}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t(`label.planting_date`)} className={"px-0"} />
        {selectDate()}
        {openDate && dateRangePicker()}
      </Card>
    </>
  )

  function changeTabs(data) {
    const urlParam = getUrlParsingSubmission({
      ...initialValue,
      keyword: searchValue
    })
    history.push(
      RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS(checkStatusByActiveTab(data) + urlParam)
    )
  }

  function checkStatusByActiveTab(data) {
    switch (data) {
      case 1:
        return ROUTING_STATUS.CERTIFICATION_STATUS_STRING.SUBMITTED
      case 2:
        return ROUTING_STATUS.CERTIFICATION_STATUS_STRING.APPROVED
      case 3:
        return ROUTING_STATUS.CERTIFICATION_STATUS_STRING.REJECTED
      case 0:
        return ROUTING_STATUS.CERTIFICATION_STATUS_STRING.PENDING
    }
  }

  function getTabsContents() {
    const labelList = [
      { label: t("label.pending") },
      { label: t("label.submitted") },
      { label: t("label.received") },
      { label: t("label.rejected") }
    ]
    const componentList = [
      <CertificationBlockPending
        key={0}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListCertification}
        status={checkStatusByActiveTab(tabActive)}
        privilege={privileges}
        fetchData={fetchData}
      />,
      <CertificationBlock
        key={1}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListCertification}
        status={checkStatusByActiveTab(tabActive)}
        privilege={privileges}
      />,
      <CertificationBlock
        key={2}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListCertification}
        status={checkStatusByActiveTab(tabActive)}
        privilege={privileges}
      />,
      <CertificationBlock
        key={3}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListCertification}
        status={checkStatusByActiveTab(tabActive)}
        privilege={privileges}
      />
    ]
    return {
      labels: labelList,
      contents: componentList
    }
  }

  function checkTabActive(tab) {
    if (tab) {
      switch (tab) {
        case ROUTING_STATUS.CERTIFICATION_STATUS_STRING.SUBMITTED:
          return 1
        case ROUTING_STATUS.CERTIFICATION_STATUS_STRING.APPROVED:
          return 2
        case ROUTING_STATUS.CERTIFICATION_STATUS_STRING.REJECTED:
          return 3
        case ROUTING_STATUS.CERTIFICATION_STATUS_STRING.PENDING:
          return 0
      }
    } else {
      return 0
    }
  }

  function onResetFilter() {
    setDateRange("")
    setIsShowFilter(false)
    setIsActiveFilter(false)
    setState({
      startDate: "",
      endDate: "",
      key: "selection"
    })
    let param = {
      ...initialFilterTable,
      variety_id: "",
      planting_date_start: "",
      planting_date_end: "",
      mitra_id: "",
      sub_district_id: "",
      keyword: searchValue
    }
    setInitialValue({
      ...initialFilterTable,
      mitra_id: "",
      sub_district_id: "",
      variety_id: "",
      planting_date_start: "",
      planting_date_end: ""
    })
    resetDate()
    setMitraFilterValue("")
    setVarietyFilterValue("")
    setLocationFilterValue("")
    const urlParam = getUrlParsingSubmission(param)
    history.push({ search: urlParam })
  }

  return (
    <div className={"d-flex flex-column"}>
      {summaryCard()}
      <FilterCard
        isActiveSearch={isActiveSearch}
        placeholder={t("placeholder.search_area_block")}
        onSubmitForm={onSearch}
        initialValues={{ keyword: searchValue }}
        name={"keyword"}
        innerRef={formRef}
        trigerReset={handleResetSearch}
        filterInnerRef={formRef}
        filterHandleSubmit={onSubmitFilter}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent}
        filterOnReset={onResetFilter}
        showFilter={isShowFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={isActiveFilter}
        btnDisable={btnFilterDisable}
      />
      <TabsWithIcon
        labelTab={getTabsContents().labels}
        componentTab={getTabsContents().contents}
        changeTab={changeTabs}
        initValue={tabActive}
      />
    </div>
  )
}
