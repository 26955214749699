import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api"
import { toAbsoluteUrl } from "../../../_metronic/_helpers/index.js"
import { ICON_CONSTANS } from "../../../constants/ImageConstants.js"
import React, { Fragment, useEffect, useRef, useState } from "react"
import SVG from "react-inlinesvg"
import {
  checkValue,
  convertDotToComma,
  toThousandConvert
} from "../../../app/helpers/TextHelper.js"
import { timeStampToDate } from "../../../app/helpers/DateHelper.js"
import { GoogleMapKey } from "../../../app/helpers/MapHelper.js"
import { DATE_TYPE_ENUM } from "../../../constants/StringConstant.js"

export const ModalMap = ({ open, setOpenMap, centroid, areas, hideDate }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GoogleMapKey()
  })
  const mapRef = useRef(null)
  const [center, setCenter] = useState({})
  const [zoom, setZoom] = useState(14)
  const onMapLoad = (map) => {
    mapRef.current = map

    areas.map((item) => {
      const polygon = new google.maps.Polygon({
        paths: item.coordinates,
        strokeColor: "#353535",
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: "#CC4435",
        fillOpacity: 0.5
      })

      polygon.setMap(map)

      const body =
        `<div class="info-windows"><h5 style="font-family: sans-serif" class="text-center mb-4">` +
        checkValue(item.area_code) +
        '</h5><div style="display: flex; flex-direction: column; margin-bottom: 10px;"><div style="display: flex;"><div style="width: 35%; margin-right: 5px;">Lokasi</div><div style="margin-right: 4px; width: 5%">:</div><div style="flex: 1">' +
        checkValue(item.location) +
        '</div></div><div style="display: flex;"><div style="width: 35%; margin-right: 5px;">Luas Lahan</div><div style="margin-right: 4px; width: 5%">:</div><div style="flex: 1">' +
        checkValue(toThousandConvert(item.land_area_meter)) +
        " m² (" +
        checkValue(convertDotToComma(item.land_area_ha)) +
        ' Ha)</div></div><div style="display: flex;"><div style="width: 35%; margin-right: 5px;">Varietas</div><div style="margin-right: 4px; width: 5%">:</div><div style="flex: 1">' +
        checkValue(item.variety_name) +
        `${
          hideDate
            ? ""
            : `</div></div><div style="display: flex;"><div style="width: 35%; margin-right: 5px;">Tanggal Tanam</div><div style="margin-right: 4px; width: 5%">:</div><div style="flex: 1">` +
              `${checkValue(timeStampToDate(item.planting_date, DATE_TYPE_ENUM.ISO_DATE_ID))}`
        }` +
        "</div></div></div></div>"

      polygon.addListener("click", () => {
        const infoWindow = new google.maps.InfoWindow()
        infoWindow.setContent(body)
        infoWindow.setPosition(item.centroid)
        infoWindow.open(map)
      })
    })
  }

  useEffect(() => {
    if (centroid) {
      setCenter(centroid)
      setZoom(17)
    }
  }, [centroid])

  const renderMap = () => {
    return (
      <div className={`modal-map ${open ? "active" : ""}`}>
        <div
          className={"btn-back flex align-items-center cursor-pointer bg-white"}
          onClick={() => setOpenMap(false)}>
          <SVG className="mr-4 mb-1" src={toAbsoluteUrl(ICON_CONSTANS.IC_ARROW_BACK)} />
          Kembali
        </div>
        <Fragment>
          <GoogleMap
            mapContainerStyle={{
              width: "100vw",
              height: "100vh"
            }}
            zoom={zoom}
            center={center}
            onLoad={(map) => onMapLoad(map)}
            mapTypeId={"satellite"}>
            {areas.map((item, index) => {
              return (
                <div key={index}>
                  <Marker
                    position={item.centroid}
                    label={{
                      text: item.area_code,
                      className: "label-map"
                    }}
                    icon={toAbsoluteUrl(ICON_CONSTANS.IC_MARKER_TRANSPARENT)}></Marker>
                </div>
              )
            })}
          </GoogleMap>
        </Fragment>
      </div>
    )
  }

  return isLoaded ? renderMap() : null
}
