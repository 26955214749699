import React, { useEffect, useRef, useState } from "react"
import { ButtonWithIcon } from "../../atoms/Button/ButtonWithIcon"
import { ICON_CONSTANS } from "../../../constants/ImageConstants"
import { Popup } from "../../atoms/Popup"
import { Button } from "react-bootstrap"
import { Field } from "formik"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { EmptyCard, Search } from "../../atoms"

export const SelectMultipleChip = ({
  name,
  label,
  labelButton,
  titleModal,
  options,
  required,
  formValue,
  setPestType,
  onClickAdd,
  setOnClose,
  onCheckClose
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [btnDisable, setBtnDisable] = useState(true)
  const [selectedValue, setSelectedValue] = useState([])
  const formRef = useRef()
  const [search, setSearch] = useState("")
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleSelectCheckbox = () => {
    setOpenModal(false)
    setSelectedValue(formValue)
    setPestType(formValue)
    setSearch("")
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setOnClose(selectedValue)
  }

  useEffect(() => {
    if (selectedValue && selectedValue.length > 0) {
      formValue = selectedValue
    }
  }, [openModal])

  useEffect(() => {
    if (!openModal) {
      setSelectedValue(formValue)
    } else {
      if (formValue && formValue.length > 0) {
        setBtnDisable(false)
      } else {
        setBtnDisable(true)
      }
    }
  }, [formValue])

  useEffect(() => {
    setPestType(selectedValue)
    if (selectedValue && selectedValue.length > 0) {
      setBtnDisable(false)
    } else {
      setBtnDisable(true)
    }
  }, [openModal, selectedValue])

  const BodyModal = (
    <>
      <div className="filter-container">
        <div className="position-relative d-flex flex-wrap justify-content-center align-items-center mb-8">
          <Search
            type={"text"}
            placeholder={"Silahkan cari hama disini"}
            innerRef={formRef}
            onChange={(e) => setSearch(e.target.value)}
            trigerReset={() => setSearch("")}
            customClass={"w-full"}
            value={search}
            initialValues={""}
          />
        </div>
      </div>
      <div className={"d-flex flex-column justify-content-start max-height-50 overflow-auto"}>
        {options &&
          options
            .filter((item) => item.label.match(new RegExp(search, "i")))
            .map((item) => {
              return (
                <div key={item.value} className={"checkbox-green d-flex align-items-center mb-6"}>
                  <Field
                    type={"checkbox"}
                    name={name}
                    key={item.value}
                    value={item.value}
                    id={name + item.value}></Field>
                  <label htmlFor={name + item.value} className={"mb-0 font-size-lg ml-4 pointer"}>
                    {item.label}
                  </label>
                </div>
              )
            })}
        {options &&
        options.filter((item) => item.label.match(new RegExp(search, "i"))).length === 0 ? (
          <EmptyCard body={"Data tidak ditemukan"} />
        ) : (
          <></>
        )}
      </div>
      <div className={"d-flex justify-content-end"}>
        <Button
          className="font-weight-bold btn-block mt-2 fit-content"
          type={"submit"}
          variant={"primary"}
          disabled={btnDisable}
          onClick={() => {
            handleSelectCheckbox()
            onClickAdd()
          }}>
          Tambahkan
        </Button>
      </div>
    </>
  )

  return (
    <>
      <Popup
        size={"md"}
        contentClassName={"modal-main"}
        show={openModal}
        titleCustom={<h2>{titleModal}</h2>}
        closeButton={true}
        showButton={false}
        body={BodyModal}
        onCancel={() => {
          handleCloseModal()
        }}
      />
      {label && (
        <label>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <div className={"d-flex flex-wrap mb-4"}>
        {selectedValue &&
          options &&
          options
            .filter((opt) => selectedValue.includes(opt.value))
            .map((item, i) => {
              return (
                <label
                  key={i}
                  htmlFor={name + item.value}
                  className="label-container mx-2 my-2 btn-filter">
                  <Field
                    type={"checkbox"}
                    name={name}
                    key={item.value}
                    value={item.value}
                    id={name + item.value}
                    className={"hidden"}
                    checked={true}></Field>
                  <div className={"label-filter d-flex align-items-center fit-content"}>
                    {item.label}
                    <div
                      onClick={() => {
                        onCheckClose(formValue)
                      }}>
                      <SVG
                        className={"ml-2 icon-checkbox-label"}
                        src={toAbsoluteUrl(ICON_CONSTANS.IC_MINI_CLOSE)}
                      />
                    </div>
                  </div>
                </label>
              )
            })}
      </div>
      <ButtonWithIcon
        label={labelButton}
        icon={ICON_CONSTANS.IC_PLUS_PRIMARY}
        onClick={handleOpenModal}
        className={"text-primary"}
      />
    </>
  )
}
