import { FormikProvider, useFormik } from "formik"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { sortCaret } from "../../../../../../_metronic/_helpers/index.js"
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { FloatingSelectArea } from "../../../../../../component/atoms/FloatingSelectArea/index.jsx"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import { formatterRowComponent } from "../../../../../../component/atoms/Tabel/index.jsx"
import {
  EmptyCard,
  NoteView,
  Search,
  SelectInput,
  TableSkeleton,
  TableView
} from "../../../../../../component/index.jsx"
import { getUrlParsing } from "../../../../../../config/EndpointCollection.js"
import {
  ICON_CONSTANS,
  ILLUSTRATION_ASSETS_V2
} from "../../../../../../constants/ImageConstants.js"
import { DATE_TYPE_ENUM } from "../../../../../../constants/StringConstant.js"
import {
  getListAreaBaLandClearing,
  getMitraListCreateBaLandClearing
} from "../../../../../../redux/actions/ReportActivityAction.jsx"
import { timeStampToDate } from "../../../../../helpers/DateHelper.js"
import { checkValue, convertM2ToHectareParseFloat } from "../../../../../helpers/TextHelper.js"
import { loadUserLogin } from "../../../../../service/userManagementAction.js"

export const SelectAreaBa = ({
  selectedArea,
  setSelectedArea,
  activeStep,
  setActiveStep,
  setDataInit,
  selectedMitra,
  setSelectedMitra,
  dataInit
}) => {
  const { t } = useTranslation()
  const [stateMitra, setStateMitra] = useState([])
  const dispatch = useDispatch()
  const regional = loadUserLogin().regional_id
  const [mitraName, setMitraName] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const { ReportActivity, General } = useSelector((state) => state)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const formik = useFormik({
    initialValues: {
      mitra_id: selectedMitra ?? "",
      keyword: ""
    },
    onSubmit: (values) => {
      const param = getUrlParsing(param, "keyword", values.keyword)
      dispatch(getListAreaBaLandClearing(formik.values.mitra_id, param))
    }
  })

  useEffect(() => {
    if (!dataInit) {
      if (regional) {
        let param = ""
        param = getUrlParsing(param, "regional_id", regional)
        dispatch(getMitraListCreateBaLandClearing(param))
      } else {
        dispatch(getMitraListCreateBaLandClearing(""))
      }
      setSelectedArea([])
    }
  }, [])

  useEffect(() => {
    if (selectedMitra) {
      dispatch(getListAreaBaLandClearing(selectedMitra, ""))
      const data = ReportActivity.filterMitraReport.find((e) => e.mitra_id === selectedMitra)
      setMitraName(data.mitra_name)
      formik.setFieldValue("keyword", "")
      setIsActiveSearch(false)
    }
  }, [formik.values.mitra_id])

  useEffect(() => {
    let param = ""
    param = getUrlParsing(param, "keyword", formik.values.keyword)
    dispatch(getListAreaBaLandClearing(selectedMitra, param))
    if (formik.values.keyword) {
      setIsActiveSearch(true)
    }
  }, [formik.values.keyword])

  useEffect(() => {
    setStateMitra(
      ReportActivity.filterMitraReport.map((value) => ({
        label: value.mitra_name,
        value: value.mitra_id
      }))
    )
  }, [ReportActivity.filterMitraReport])

  function checkTotalAreaMeter() {
    let total = 0
    selectedArea.map((item) => {
      total = Number(total) + Number(item.planting_area)
    })
    return total
  }

  useEffect(() => {
    setDataInit({
      mitra_name: mitraName,
      total_land_area: checkTotalAreaMeter(),
      area: selectedArea
    })
  }, [mitraName, selectedArea])

  const changePage = useCallback(
    (param) => {
      let urlParam = ""
      if (formik.values.keyword != null) {
        urlParam = getUrlParsing(urlParam, "keyword", formik.values.keyword)
      }
      if (param.sortField != null) {
        urlParam = getUrlParsing(
          urlParam,
          "sort",
          param.sortField ? param.sortField + "," + param.sortOrder : ""
        )
      }
      if (param.pageSize != null) {
        urlParam = getUrlParsing(urlParam, "size", param.pageSize)
      }
      if (param.pageNumber != null) {
        urlParam = getUrlParsing(urlParam, "page", param.pageNumber - 1)
      }
      dispatch(getListAreaBaLandClearing(selectedMitra, urlParam))
    },
    [dispatch, selectedMitra, searchValue]
  )

  const mitraSelect = (
    <Card className={"border-0 p-4"}>
      <CardBody>
        <RowModule>
          <SelectInput
            name="mitra_id"
            required
            onChange={(value) => {
              formik.setFieldValue("mitra_id", value)
              setSelectedMitra(value)
              setSelectedArea([])
              setIsActiveSearch(false)
              setSearchValue("")
              formik.setFieldValue("keyword", "")
            }}
            editable={true}
            label={t("label.mitra_name")}
            placeholder={t("placeholder.select_one")}
            value={selectedMitra ?? formik.values.mitra_id}
            options={stateMitra}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  function uiCode(e) {
    return (
      <>
        <div className={"text-dark font-weight-bolder  font-size-lg"}>
          {checkValue(e?.area_name)}
        </div>
        <div className={"d-flex"}>
          <div>{`${checkValue(e?.sub_district_name)}, ${checkValue(
            convertM2ToHectareParseFloat(e.planting_area),
            "Ha"
          )}`}</div>
        </div>
      </>
    )
  }

  function uiBlock(e) {
    return (
      <>
        <div className={"text-dark font-weight-bolder  font-size-lg"}>
          {checkValue(e?.block_code)}
        </div>
      </>
    )
  }

  function uiFarmer(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {checkValue(e?.farmer_name)}
        </div>
        <div>{checkValue(e?.farmer_phone_number)}</div>
      </div>
    )
  }
  function uiVariety(e) {
    return (
      <>
        <div className={"text-dark font-weight-bolder  font-size-lg"}>
          {checkValue(e?.variety_name)}
        </div>
      </>
    )
  }
  function uiDate(e) {
    return (
      <>
        <div>
          <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
            {checkValue(timeStampToDate(e?.planting_date, DATE_TYPE_ENUM.ISO_DATE_STRAP))}
          </div>
          <div>{checkValue(e.hst, "HST")}</div>
        </div>
      </>
    )
  }

  const columns = [
    {
      dataField: "area_name",
      text: t("table_header.area_name"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "block_name",
      text: t("table_header.block_name"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiBlock
      }
    },
    {
      dataField: "farmer",
      text: t("table_header.farmer"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiFarmer
      }
    },
    {
      dataField: "variety_name",
      text: t("label.plant_varieties"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiVariety
      }
    },
    {
      dataField: "plantingActivity.activityDate",
      text: t("table_header.planting_date"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      },
      sortCaret: sortCaret
    }
  ]

  return (
    <div className={"d-flex row justify-content-center w-full py-10"}>
      {selectedArea.length > 0 && (
        <FloatingSelectArea
          totalArea={selectedArea.length}
          buttonlabel={t("label.fill_area_detail")}
          labelIcon={ICON_CONSTANS.IC_MAP_AREA}
          withIcon={true}
          handleClick={() => {
            setActiveStep(activeStep + 1)
          }}
          buttonColor={"primary"}
        />
      )}
      <div className={"w-80vw mb-4"}>
        <FormikProvider value={formik}>{mitraSelect}</FormikProvider>
      </div>
      {formik.values.mitra_id && (
        <div>
          <div className={"w-80vw mb-4"}>
            <div className={"font-size-h3 font-weight-bolder"}>{t("label.select_active_area")}</div>
          </div>
          <div className={"w-80vw mb-4"}>
            <NoteView
              variant={"success"}
              title={t("messages.alert.note_active_area_list_land_clearing")}
              titleClassName={"font-size-lg"}
            />
          </div>
          <div className={"w-80vw mb-4"}>
            <Card>
              <CardBody>
                <div className="filter-container px-2">
                  <div className="position-relative d-flex flex-wrap justify-content-between align-items-center my-8">
                    <Search
                      placeholder={t("placeholder.search_area_block")}
                      onSubmitForm={(value) => {
                        formik.setFieldValue("keyword", value.keyword)
                        setSearchValue(value.keyword)
                        formik.handleSubmit
                      }}
                      initialValues={{ keyword: searchValue }}
                      name={"keyword"}
                      isActiveSearch={isActiveSearch}
                      trigerReset={() => {
                        formik.setFieldValue("keyword", "")
                        setSearchValue("")
                        formik.handleSubmit
                        setIsActiveSearch(false)
                      }}
                    />
                  </div>
                </div>
                {General.loadingComponent ? (
                  <TableSkeleton column={8} row={10} />
                ) : ReportActivity?.pageData?.length === 0 ? (
                  <EmptyCard
                    image={
                      formik.values.keyword
                        ? ILLUSTRATION_ASSETS_V2.IL_NOT_FOUND_DATA
                        : ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_DATA
                    }
                    body={
                      formik.values.keyword
                        ? t("messages.response.response_not_found_data")
                        : t("messages.response.empty_state", { name: "lahan" })
                    }
                    subtitle={
                      formik.values.keyword
                        ? t("messages.response.sub_response_not_found_data")
                        : ""
                    }
                  />
                ) : (
                  ""
                )}
                <TableView
                  dataTable={ReportActivity.pageData ?? []}
                  callbackAfterPageChange={changePage}
                  columnProperties={columns}
                  currentPage={ReportActivity?.page?.currentPage}
                  currentElement={ReportActivity?.page?.currentElement}
                  totalCount={ReportActivity?.page?.totalCount}
                  keyField={"id"}
                  selectable={true}
                  selectedParam={selectedMitra}
                  rowClasses={() => {
                    return "cursor-pointer"
                  }}
                  selected={selectedArea.map((item) => item.id)}
                  onSelect={(row, isSelected) => {
                    let rows = [...selectedArea]
                    if (isSelected) {
                      rows.push(row)
                    } else {
                      rows = rows.filter((e) => e.id !== row.id)
                    }
                    setSelectedArea(rows)
                  }}
                  onSelectAll={(isSelect, rows) => {
                    let rowses = [...selectedArea]
                    if (isSelect) {
                      rowses = [...rowses, ...rows]
                    } else {
                      rowses = rowses.filter(
                        (selectedRow) => !rows.some((row) => row.id === selectedRow.id)
                      )
                    }
                    setSelectedArea(rowses)
                  }}
                  loadingGetData={false}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </div>
  )
}
