import { createSlice } from "@reduxjs/toolkit"

const initReport = {
  pageData: [],
  page: false,
  data: null,
  dataDownload: null,
  filterMitraReport: [],
  listAreaReport: [],
  responseCreateBa: null,
  listReason: [],
  listPest: [],
  listChildReason: []
}

export const ReportActivitySlice = createSlice({
  name: "reportActivity",
  initialState: initReport,
  reducers: {
    getPage: (state, action) => {
      state.data = action.payload
    },
    setPageData: (state, action) => {
      const { pageData, page } = action.payload
      state.pageData = pageData
      state.page = page
    },
    setListArea: (state, action) => {
      state.listAreaReport = action.payload
    },
    setFilterMitra: (state, action) => {
      state.filterMitraReport = action.payload
    },
    setResponseCreateBa: (state, action) => {
      state.responseCreateBa = action.payload
    },
    setListReason: (state, action) => {
      state.listReason = action.payload
    },
    setListPest: (state, action) => {
      state.listPest[action.payload.index] = action.payload.data.pest_type
    },
    setListChildReason: (state, action) => {
      state.listChildReason[action.payload.index] = action.payload.data
    },
    setCsvDataDownload: (state, action) => {
      state.dataDownload = action.payload
    }
  }
})
