import { differenceInDays, parseISO } from "date-fns"
import { useFormikContext } from "formik"
import React from "react"
import { Card } from "react-bootstrap"
import BootstrapTable from "react-bootstrap-table-next"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { CardBody } from "../../../../../../_metronic/_partials/controls"
import { formatterRowComponent } from "../../../../../../component/atoms/Tabel"
import { DATE_TYPE_ENUM } from "../../../../../../constants/StringConstant"
import { timeStampToDate } from "../../../../../helpers/DateHelper"
import { checkValue, convertM2ToHectareParseFloat } from "../../../../../helpers/TextHelper"

const SelectedAreaList = () => {
  const { t } = useTranslation()
  const { values: formValues } = useFormikContext()
  const { ReportActivity } = useSelector((state) => state)

  const uiArea = (e) => {
    return <>{checkValue(convertM2ToHectareParseFloat(e?.planting_area), "Ha")}</>
  }

  const uiActivityDate = (e) => {
    return (
      <>
        {timeStampToDate(checkValue(e.activity_date), DATE_TYPE_ENUM.ISO_DATE_STRAP)}{" "}
        {`(${checkValue(
          differenceInDays(parseISO(e.activity_date), parseISO(e.planting_date))
        )} HST)`}
      </>
    )
  }

  const uiReason = (e) => {
    return <>{ReportActivity.listReason.find((item) => item.id === e?.reason_id)?.name ?? "-"}</>
  }

  const columns = [
    {
      dataField: "area_name",
      text: "Nama Lahan",
      headerClasses: "bg-gray"
    },
    {
      dataField: "block_code",
      text: "Nama Blok",
      headerClasses: "bg-gray"
    },
    {
      dataField: "farmer_name",
      text: "Petani",
      headerClasses: "bg-gray"
    },
    {
      dataField: "planting_area",
      text: "Luas",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiArea
      }
    },
    {
      dataField: "variety_name",
      text: "Varietas Tanaman",
      headerClasses: "bg-gray"
    },
    {
      dataField: "activity_date",
      text: "Tanggal Bongkar",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiActivityDate
      }
    },
    {
      dataField: "reason_id",
      text: "Alasan Bongkar",
      headerClasses: "bg-gray",

      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiReason
      }
    }
  ]

  return (
    <Card className="mb-4">
      <CardBody>
        <h5 className={"mb-8 text-uppercase font-size-h5"} style={{ color: "#353535" }}>
          {t("selected_area_list")}
        </h5>
        <div className={"border rounded table-small-padding"}>
          <BootstrapTable
            condensed
            bordered={false}
            columns={columns}
            keyField={"block_code"}
            data={formValues.area}
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default SelectedAreaList
